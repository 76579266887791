/* Text Input */

textarea {
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(213, 218, 237, 1);
  border-radius: 4px;
  box-shadow: inset 0px 1px 2px 0px rgba(29, 31, 41, 0.15),
    0px 0px 0px 0px rgba(56, 95, 194, 1);
  box-sizing: border-box;
  color: rgba(46, 50, 66, 1);
  font: 400 14px helvetica;
  line-height: 19px;
  margin: 0px 0px 10px;
  min-height: 75px;
  outline: 0px;
  padding: 7px 10px;
  transition: all 0.35s;
  width: 100%;
  resize: vertical;
}
textarea::placeholder {
  color: rgba(125, 132, 161, 0.5);
  transition: all 0.35s;
}
textarea:hover {
  border: 1px solid rgba(56, 95, 194, 1);
  transition: all 0.35s;
}
textarea:hover::placeholder {
  color: rgba(125, 132, 161, 1);
  transition: all 0.35s;
}
textarea:focus {
  border: 1px solid rgba(56, 95, 194, 1);
  box-shadow: inset 0px 0px 0px 0px rgba(29, 31, 41, 0.15),
    0px 0px 0px 1px rgba(56, 95, 194, 1);
  color: rgba(69, 130, 229, 1);
  transition: all 0.35s;
}
textarea:disabled {
  background: rgba(230, 232, 242, 1);
  border: 1px solid rgba(213, 218, 237, 1);
  box-shadow: 0px 0px 0px 0px rgba(29, 31, 41, 0),
    0px 0px 0px 0px rgba(56, 95, 194, 1);
  color: rgba(125, 132, 161, 1);
}
textarea:disabled::placeholder {
  color: rgba(125, 132, 161, 1);
}
textarea:disabled:hover,
textarea:disabled:focus {
  border: 1px solid rgba(213, 218, 237, 1);
  color: rgba(125, 132, 161, 1);
  cursor: not-allowed;
}
textarea:disabled:hover::placeholder,
textarea:disabled:focus::placeholder {
  color: rgba(125, 132, 161, 1);
}
